.main-navbar {
  background: #064635;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.logo-img {
  margin: 10px 0 5px -8px;
}

.logo-text {
  width: 90px;
  float: left;
  margin: 25px 0 0 -7px;
  color: #fff;
}

.church-logo {
  font-size: 10px;
  margin-top: -15px;
  width: 120px;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.main-nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-top: 1rem;
  margin-bottom: 0px;
}

.main-nav-item {
  display: flex;
  align-items: center;
}

.main-nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.main-nav-links:hover {
  background-color: #051367;
  color: #fff;
  border-radius: 4px;
  /* transition: all 0.2s ease-out; */
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.main-menu-icon {
  display: none;
}
