.main-dropdown-menu {
  background: #064635;
  width: 90px;
  position: absolute;
  top: 57px;
  list-style: none;
  text-align: left;
  z-index: 99999;
}

.main-dropdown-menu li {
  background: #161E54;
  margin: 0px -50px;
  cursor: pointer;
}

.main-dropdown-menu li:hover {
  /* background: #051367; */
  text-decoration: underline;
}

.main-dropdown-menu.clicked {
  display: none;
}

.main-dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 14px;
}

.main-dropdown-link:hover {
  color: #fff;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
