@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

html,body
{
  width: 100%;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden; 
}

/* NAVBAR */
.logo-img {
  height: 50px;
  width: 60px;
}

.intro-img {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 280;
  right: 0;
  width: 70px;
  height: 70px;
  margin: 70px auto 0;
  justify-content: center;
  align-items: center;
}

/* ABOUT */
.card-img {
  height: 250px;
  width: 50px;
}

.title {
  text-align: center;
  margin: 20px;
  font-size: 2rem;
}

.centered {
  text-align: center;
  margin: 70px auto;
}

.carousel1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.7)) , url("../images/new-images/image3.jpeg");
  background-position: center;
  background-size: cover;
  height: 600px;
}

.carousel2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.7)) , url("../images/new-images/image4.jpeg");
  background-position: center;
  background-size: cover;
  height: 600px;
}

.carousel3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.7)) , url("../images/new-images/image1.jpeg");
  background-position: center;
  background-size: cover;
  height: 600px;
}

/* .intro {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.7)) , url("../images/Pecuty-2/IMG-20211024-WA0043.jpg");
  background-position: center;
  background-size: cover;
  height: 550px;
  margin-top: 0px;
  background-repeat: no-repeat;
} */

.our-classes {
  text-decoration: none;
  color: black;
}

.our-classes:hover {
  color: black;
  opacity: .9;
}

.testimonials {
  background-image: linear-gradient(rgba(0, 0, 0, 0.95),rgba(0, 0, 0, 0.7)) , url("../images/Pecuty-2/IMG-20211024-WA0036.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}


.underline {
  text-decoration: underline;
}


/* NAVBAR DROPDOWN */

.dropdown:hover>.dropdown-menu {
  /* display: block; */
}

.dropdown>.dropdown-toggle:active {
    pointer-events: none;
}

/* Admissions */
.admission-process {
  background-color: blue;
background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(7, 10, 102, 0.8)) , url("https://www.transparenttextures.com/patterns/3px-tile.png");
}

.steps-to-apply {
  background-color: white;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 30px;
  color: rgba(7, 11, 139, 0.8);
}


/* PORTAL */
.portal-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.9)) , url("../images/Pecuty-2/IMG-20211024-WA0044.jpg");
  background-position: center;
  background-size: cover;
  height: 587px;
  text-align: center;
  /* background-attachment: fixed; */
}

.portal-center {
  margin: 0 auto 0;
  width: 290px;
}

.objective-list li {
    font-size: 16px;
    margin-left: 10px;
    list-style-type: circle;
    line-height: 2;
 }

 .reviews {
   font-size: 20px;
   font-weight: lighter;
 }


/* FOOTER */
.main-footer {
  margin-top: 50px;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f1f1fa;
}

.main-footer::before {
  background-image: url("../images/pecuty-royal-logo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: .1;
  /* background-color: #161E54; */
}

.footer-background {
  position: relative;
    color: #161E54;
}

.footer-number, .footer-email {
  display: block;
  color: #161E54;
  text-decoration: none;
  text-align: left;
  width: 330px;
}

.footer-link {
  text-decoration: none;
  color: #161E54;
}

.footer-link:hover {
  color: blue;
}


/* CLASSES  */
.creche-header-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.7)) , url("../images/Pecuty-2/IMG-20211026-WA0002.jpg");
  background-position: center;
  background-size: cover;
  height: 500px;
}

.nursery-header-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.7)) , url("../images/Pecuty-2/IMG-20211026-WA0012.jpg");
  background-position: center;
  background-size: cover;
  height: 500px;
}

.primary-header-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.7)) , url("../images/Pecuty-2/IMG-20211024-WA0043.jpg");
  background-position: center;
  background-size: cover;
  height: 500px;
}

.secondary-header-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.7)) , url("../images/Pecuty-2/IMG-20211024-WA0045.jpg");
  background-position: center;
  background-size: cover;
  height: 500px;
}

/* ANTHEM */
.main-anthem {
  margin: -30px auto;
  /* width: 500px; */
  background-color: white;
  padding: 15px;
}

/* ABOUT US */
.background {
  margin: 15px;
}

.core-values {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  background-color: #FFD523;
  position: relative;
  margin: 20px auto;
  box-shadow: 7px 5px 1px #161E54;
}

.main-values {
  position: absolute;
  left: -5px;
  top: 20px;
  right: 10px;
  margin: 10px 5px;
}

.main-values li {
  list-style-type: none;
  display: inline-block;
  padding: 5px;
  font-weight: bold;
}

.staff {
  box-shadow: 1px 0px 5px black;
  width: 260px;
  margin: 0 auto;
}

.staff-column {
  margin: 0 auto;
  text-align: center;
  /* margin-right: 30px; */
}

.staff-image {
  /* border-radius: 10px; */
  /* border-bottom-left-radius: 0; */
  /* border-bottom-right-radius: 0; */
  width: 260px;
}

/* CONTACT */
.main-contact {
  margin: -35px auto 50px;
  background-color: white;
  padding: 20px;
  height: 500px;
  /* width: 600px; */
}

.contact-form {
  margin: 30px auto;
  /* width: 500px; */
}

.blue-underline {
    font-weight: 300px;
    display: inline-block;
    padding-bottom: 5px;
    position: relative;
}

.blue-underline:before{
    content: "";
    position: absolute;
    width: 30%;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 3px solid #161E54;
}

.white-underline {
    font-weight: 300px;
    display: inline-block;
    padding-bottom: 5px;
    position: relative;
}

.white-underline:before{
    content: "";
    position: absolute;
    width: 30%;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 3px solid #fff;
}

.media-top {
  text-align: center;
}

.media-css {
  /* text-align: center; */
}

.media-heading {
  font-weight: bolder;
  text-shadow: 3px 3px 2px lightgrey;
  /* margin-left: 50px; */
}

.media-css ul {
  margin-left: -30px;
}

.media-css ul li {
  list-style-type: none;
  display: inline;
  padding: 5px;
}

.media-css ul li a {
  text-decoration: none;
  color: black;
}

.media-css ul li a:hover {
  text-decoration: underline;
}

.page-not-found {
  margin: 200px auto;
  text-align: center;
}

.media-hr {
  width: 50px;
  margin: 10px auto;
  color: rgb(53, 2, 2);
  padding: 1px;
}

.login-users {
  border: 1px solid black;
}


/* MEDIA QUERIES */
@media (min-width: 300px) and (max-width: 700px) {
  .centered {
    align-items: center;
    margin: 70px 20px;
  }
}

@media (max-width: 780px) {
  .intro-text {
    font-size: 150%;
  }
}

@media (min-width: 996px) {
  .background {
  margin: 20px 70px;
}

  .main-contact {
  width: 600px;
  box-shadow: 1px 0px 3px #161E54;
}

  .main-anthem {
  width: 500px;
  box-shadow: 1px 0px 3px black;
}
}

@media (max-width: 996px) {
  .media-css ul li {
  list-style-type: none;
  display: block;
  padding: 5px;
}

.media-css ul li a:hover {
  text-decoration: none;
  padding: 5px 40%;
  border: 2px solid rgb(53, 2, 2);
}
}
